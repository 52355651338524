<template>
  <div>
    <!--begin::DocumentVQA-->
    <div class="row">
      <div class="col-lg-12 col-xxl-12 mb-10">
        <b-card-group deck>
          <b-card
            tag="article"
            bg-variant="primary"
            text-variant="white"
            class="mb-2"
          >
            <b-card-body>
              <span class="svg-icon svg-icon-2x svg-icon-white">
                <inline-svg
                  src="/media/svg/icons/Layout/Layout-horizontal.svg"
                />
              </span>
              <b-card-title
                class="font-size-h2 mb-0 mt-6 font-weight-bolder d-block"
              >
                {{ record.overall }}
              </b-card-title>
              <span class="font-weight-bold text-white font-size-sm">
                Overall result
              </span>
            </b-card-body>
          </b-card>
          <b-card
            tag="article"
            bg-variant="warning"
            text-variant="white"
            class="mb-2"
          >
            <b-card-body>
              <span class="svg-icon svg-icon-2x svg-icon-white">
                <inline-svg src="/media/svg/icons/Files/Selected-file.svg" />
              </span>
              <b-card-title
                class="font-size-h2 mb-0 mt-6 font-weight-bolder d-block"
              >
                {{ record.qa }}
              </b-card-title>
              <span class="font-weight-bold text-white font-size-sm">
                Document QA<br />(Mean ANLS)
              </span>
            </b-card-body>
          </b-card>
          <b-card
            tag="article"
            bg-variant="danger"
            text-variant="white"
            class="mb-2"
          >
            <b-card-body>
              <span class="svg-icon svg-icon-2x svg-icon-white">
                <inline-svg src="/media/svg/icons/Communication/Chat1.svg" />
              </span>
              <b-card-title
                class="font-size-h2 mb-0 mt-6 font-weight-bolder d-block"
              >
                {{ record.kie }}
              </b-card-title>
              <span class="font-weight-bold text-white font-size-sm">
                Key Information Extraction<br />(Mean F1)
              </span>
            </b-card-body>
          </b-card>
          <b-card
            tag="article"
            bg-variant="dark"
            text-variant="white"
            class="mb-2"
          >
            <b-card-body>
              <span class="svg-icon svg-icon-2x svg-icon-white">
                <inline-svg src="/media/svg/icons/Layout/Layout-grid.svg" />
              </span>
              <b-card-title
                class="font-size-h2 mb-0 mt-6 font-weight-bolder d-block"
              >
                {{ record.table }}
              </b-card-title>
              <span class="font-weight-bold text-white font-size-sm">
                Table QA/NLI<br />(Mean Accuracy)
              </span>
            </b-card-body>
          </b-card>
        </b-card-group>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-lg-4 col-xxl-4">
        <b-card title="Description" tag="article" class="mb-2">
          <b-card-text>
            We rely on the extended T5 model as a competitive baseline to
            identify the current level of performance on the chosen tasks and
            facilitate future research. The introduced modifications is the
            addition of 2D positional bias that has been shown to perform well
            on tasks that demand layout understanding.
          </b-card-text>

          <b-button
            href="https://openreview.net/forum?id=rNs2FvJGDK"
            variant="primary"
            >Publication</b-button
          >
        </b-card>
      </div> -->
      <div class="col-lg-6 col-xxl-6">
        <div
          role="alert"
          v-if="errors && errors.length"
          class="alert fade alert-info show"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
        <div
          role="alert"
          v-if="message && message.length"
          class="alert fade alert-success show"
        >
          <div class="alert-text">
            {{ message }}
          </div>
        </div>
        <b-form
          @submit="onSubmit"
          v-if="currentUser && record.user == currentUser.id"
        >
          <b-form-group
            id="input-group-2"
            label="Name of the solution"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              v-model="form.name"
              placeholder="Enter name"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Description"
            label-for="textarea-rows"
            description="Description of the solution (can be edited or filled later)"
          >
            <b-form-textarea
              id="textarea-rows"
              placeholder="Provide description (optional)"
              v-model="form.description"
              rows="8"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            id="input-group-1"
            label="Link to the publication"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.link"
              placeholder="Enter link (optional)"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-rules">
            <b-form-checkbox-group id="public">
              <b-form-checkbox
                v-model="form.public"
                true-value="true"
                false-value="false"
              >
                Make this solution visible by other users.
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-button type="submit" variant="primary">Save</b-button>
        </b-form>
        <b-card
          title="Description"
          tag="article"
          class="mb-2"
          v-if="!currentUser || record.user != currentUser.id"
        >
          <b-card-text>
            {{ record.description }}
          </b-card-text>

          <b-button
            v-if="record.link"
            :href="record.link"
            variant="primary"
            target="_blank"
            >Publication</b-button
          >
        </b-card>
        <!-- <b-card tag="article" class="mb-2">
          <b-card-text>
            <h5>Overall result</h5>
            <b-progress
              :value="record.overall"
              :max="100"
              :precision="2"
              show-value
              class="mb-10"
            ></b-progress>
            <h5>Components</h5>
            Document QA
            <b-progress
              :value="record.qa"
              :max="100"
              :precision="2"
              show-value
              variant="warning"
              class="mb-3"
            ></b-progress>
            Key Information Extraction
            <b-progress
              :value="record.kie"
              :max="100"
              :precision="2"
              show-value
              variant="danger"
              class="mb-3"
            ></b-progress>
            Table QA/NLI
            <b-progress
              :value="record.table"
              :max="100"
              :precision="2"
              show-value
              variant="dark"
              class="mb-3"
            ></b-progress>
          </b-card-text>
        </b-card> -->
      </div>
      <div class="col-lg-6 col-xxl-6">
        <b-card title="Individual scores" tag="article" class="mb-2">
          <b-card-text>
            <div id="chart">
              <apexchart
                type="bar"
                height="200"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
    <!--end::DocumentVQA-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_SINGLE_SUBMISSION,
  UPDATE_SUBMISSION
} from "@/core/services/store/auth.module";
import config from "@/core/config/layout.config.json";
import { mapState, mapGetters } from "vuex";

export default {
  name: "leaderboard",
  components: {
    // ListWidget3,
    // StatWidget7,
    // Paper
  },
  data() {
    const colors = [
      config.colors.theme.base.warning,
      config.colors.theme.base.warning,
      config.colors.theme.base.danger,
      config.colors.theme.base.danger,
      config.colors.theme.base.danger,
      config.colors.theme.base.dark,
      config.colors.theme.base.dark
    ];
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: "bar"
        },
        colors: colors,
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [
            "DocVQA",
            ["Infographics", "VQA"],
            ["Kleister", "Charity"],
            "PWC",
            "DeepForm",
            ["WikiTable", "Questions"],
            "TabFact"
          ],
          labels: {
            style: {
              colors: colors,
              fontSize: "12px"
            }
          }
        }
      }
    };
  },
  created() {
    this.$store
      .dispatch(GET_SINGLE_SUBMISSION, this.$route.params.id)
      .then(data => {
        let title = data.name;
        if (!data.public) {
          title += " (private)";
        }
        this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
      })
      .catch(() => {
        this.$router.push({ name: "404" });
      });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(UPDATE_SUBMISSION, {
          link: this.form.link,
          name: this.form.name,
          description: this.form.description,
          public: !!this.form.public,
          id: this.$route.params.id,
          checked: true
        })
        .then(data => {
          let title = data.name;
          if (!data.public) {
            title += " (private)";
          }
          this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
        });
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    ...mapState({
      errors: state => state.auth.errors,
      message: state => state.auth.message,
      record: state => state.auth.details,
      series: state => [
        {
          name: "Score",
          data: [
            state.auth.details.docvqa ? state.auth.details.docvqa : 0,
            state.auth.details.infographics
              ? state.auth.details.infographics
              : 0,
            state.auth.details.charity ? state.auth.details.charity : 0,
            state.auth.details.pwc ? state.auth.details.pwc : 0,
            state.auth.details.deepform ? state.auth.details.deepform : 0,
            state.auth.details.wtq ? state.auth.details.wtq : 0,
            state.auth.details.tabfact ? state.auth.details.tabfact : 0
          ]
        }
      ],
      form: state => {
        return {
          link: state.auth.details.link,
          name: state.auth.details.name,
          description: state.auth.details.description,
          public: state.auth.details.public
        };
      }
    })
  }
};
</script>
